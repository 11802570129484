.map-content {
    pointer-events: none;
    .maps-search-container,
    .maps-search-input,
    input,
    button {
        pointer-events: all;
    }

    button {
        cursor: pointer;
    }

    .map-controls {
        button {
            padding: 3px;
            height: 40px;
            width: 40px;
            border-radius: 5px;
            background: $light-background;
            outline: none;
            box-shadow: $default-box-shadow;
            border-left: 1px solid #f0f1f2;
            border-top: 1px solid #f0f1f2;
            border-right: 1px solid transparent;
            border-bottom: 1px solid #f0f1f2;
            img {
                width: 100%;
            }
            &:hover,
            &.selected {
                border: 1px solid $map-controls-button-hover-bg;
                background-color: $map-controls-button-hover-bg;
            }
        }

        .map-control-tooltip {
            background-color: white;
            box-shadow: $default-box-shadow;
            &:before {
                border-bottom-color: white;
            }
        }
    }

    .map-type-buttons button,
    .map-zoom-buttons button {
        border-radius: 5px;
        background: $light-background;
        outline: none;
        box-shadow: $default-box-shadow;
        border-left: 1px solid #f0f1f2;
        border-top: 1px solid #f0f1f2;
        border-right: 1px solid transparent;
        border-bottom: 1px solid #f0f1f2;
    }
}
