.layout-sidenav-container {
    a.active,
    button.active {
        background-color: $dark-bg-alpha-4;
        color: $dark-primary-text;
    }
}

.block-form {
    .mat-form-field {
        display: block;
        margin-bottom: 10px;
    }
}
