@import "../themes/default/theme.scss";
@import "./_variables.scss";
@import "../app/modules/component-set/component-set.scss";
@import "../app/modules/drawing/drawing.scss";


html,
body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}
body, button {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
}

.presenter {
    box-sizing: border-box;
    background: $presenter-background;
    width: 100%;
    @media only screen and (max-width: 959px) {
        padding: 3px;
    }
    @media only screen and (min-width: 960px) {
        min-width: 960px;
        box-shadow: $presenter-shadow;
        border-radius: $presenter-border-radius;
    }
}

.simple-toolbar {
    display: flex;

    .toolbar-item-spacer {
        flex: 1 1 auto;
    }
}

.main-layout {
    @import "../themes/default/main.scss";
}

.management-layout {
    @import "../themes/default/management.scss";
}

input.no-updown::-webkit-outer-spin-button,
input.no-updown::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input.no-updown[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

a[disabled] {
    pointer-events: none;
    cursor: default;
}
