.block-form {
    .mat-form-field {
        display: block;
        margin-bottom: 10px;
    }
}

.container {
    .information-box {
        background: $light-background;
    }
}

.animal-selector-component {
    .animal-button-wrapper {
        border-radius: $default-border-radius;
        box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.43);
        border: none;
        color: $dark-text;

        &:hover,
        &.selected {
            background: $dark-background;
            color: $light-text;
            border-color: $light-text;
        }
    }
}

.main-drawing-map {
    .map-container {
        border-radius: $default-border-radius;
        box-shadow: $default-box-shadow;
    }
}

.default-shadow {
    box-shadow: $default-box-shadow;
}

.stepper-button-container {
    .animal-stepper-image {
        background: $light-background;
        border-radius: 8px;
    }
}

.additional-stepper-buttons {
    button {
        outline: none;
        img { 
            border-radius: $default-border-radius;
            box-shadow: $default-box-shadow;
        }
    }
}

.error-color {
    color: #ff2700;
}
