.stepper-button-container {

    button.stepper-button {
        background: $stepper-button-bg;
        border-radius: 10px;
        box-shadow: $default-box-shadow;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .stepper-button-tooltip {
        background-color: $stepper-button-tooltip-bg;
        border-radius: 11px;
        cursor: default;
        box-shadow: $default-box-shadow;
        &:before {
            border-bottom-color: $stepper-button-tooltip-bg;
        }
    }
}
